.autocomplete-row{
    display: inline-flex;
    width: 100%;
    margin-right: 24px;
    margin-left: 26px;
    margin-top: 2px;
    margin-bottom: 5px;
}

.autocomplete-search{
    width: 85%;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.autocomplete-icon-button{
    border-radius: 0.25rem;
    padding-top: 0.6rem;
    font-size: 20px;
    min-width: 3vw;
    text-align: center;
    color: $white;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background-color: $theme-color;
}