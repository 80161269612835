/*------- 11. About style ------ */
$border-color: grey;
$font-color: black;
$default-keyboard-color: #86827f;
$correct-color: #a2b63d;
$incorrect-color: #d3d3d3;
$almost-color: #ffdb70;
$light-font-color: white;
$keyboard-key-width: 30px;
$keyboard-key-height: 29px;
$letter-width: 61px;
$letter-height: 59px;
$large-keyboard-key-width: 92px;

.grid {
  flex: 33%;
  display: flex;
  flex-direction: column;
}

.gridRow {
  justify-content: center;
  flex: 33%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}

.letterSquare {
  width: $letter-width;
  height: $letter-height;
  min-width: $letter-width;
  min-height: $letter-height;
  border: 1px solid $border-color;
  margin: 5px;
}

.letter {
  font-size: 30px;
  font-weight: bolder;
  color: $font-color;
  transform: translate(25%, 50%);
}

.correct {
  background-color: $correct-color;
}

.wrongColumn {
  background-color: $almost-color;
}

.error {
  background-color: $incorrect-color;
}

.keyboard {
  flex: 33%;
  display: flex;
  flex-direction: column;
}

.line {
  flex: 33%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5px;
}

.defaultKey {
  width: $keyboard-key-width;
  height: $keyboard-key-height;
  min-width: $keyboard-key-width;
  min-height: $keyboard-key-height;
  margin: 5px;
  border-radius: 4px;
  display: grid;
  place-items: center;
  font-size: 20px;
  background-color: $default-keyboard-color;
  color: $light-font-color;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
}

.correctKey {
  width: $keyboard-key-width;
  height: $keyboard-key-height;
  min-width: $keyboard-key-width;
  min-height: $keyboard-key-height;
  margin: 5px;
  border-radius: 4px;
  display: grid;
  place-items: center;
  font-size: 20px;
  background-color: $correct-color;
  color: $light-font-color;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
}

.wrongColumnKey {
  width: $keyboard-key-width;
  height: $keyboard-key-height;
  min-width: $keyboard-key-width;
  min-height: $keyboard-key-height;
  margin: 5px;
  border-radius: 4px;
  display: grid;
  place-items: center;
  font-size: 20px;
  background-color: $almost-color;
  color: $light-font-color;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
}

.errorKey {
  width: $keyboard-key-width;
  height: $keyboard-key-height;
  min-width: $keyboard-key-width;
  min-height: $keyboard-key-height;
  margin: 5px;
  border-radius: 4px;
  display: grid;
  place-items: center;
  font-size: 20px;
  background-color: $incorrect-color;
  color: $light-font-color;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
}

.big {
  width: $large-keyboard-key-width;
  min-width: $large-keyboard-key-width;
}

.zordle-overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
}

.zordle-popup {
  margin: 70px auto;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  width: 30%;
  position: relative;
  text-align: center;
  transition: all 5s ease-in-out;
}

.zordle-popup h2 {
  margin-top: 0;
  color: black;
  font-family: Tahoma, Arial, sans-serif;
}