.announcement-title {
  align-items: center;
  align-content: center;
  display: inline-block;
}
.announcement-nav-item .nav-link {
  border-radius: 0px;
  font-size: 16px;
  border-radius: 10px 10px 0px 0px;
}

.announcement-nav-item .nav-link:hover {
  color: $theme-secondary-color;
  border-left: 2px $theme-secondary-color solid;
  border-right: 2px $theme-secondary-color solid;
  border-top: 2px $theme-secondary-color solid;
}

.announcement-nav-item .nav-link.active {
  background-color: transparent;
  color: $theme-color;
  border-left: 2px $theme-color solid;
  border-right: 2px $theme-color solid;
  border-top: 2px $theme-color solid;
  border-radius: 10px 10px 0px 0px;
}
.announcement-horizontal-list {
  display: inline-flex;
  overflow-x: scroll;
  width: 100%;
  background-color: #f7f7f7;
  border-radius: 8px;
  border-top: 2px $theme-default solid;

  .tack {
    margin-top: -30px;
    margin-bottom: 10px;
    padding-left: 45%;

    img {
      width: 2rem;
    }
  }
  .va-side-panel {
    max-height: 80vh;
    min-height: 80vh;
    margin-bottom: 40px;
    margin-top: 40px;
  }
  .sticky-note {
    max-width: 40rem;
    min-width: 40rem;
    max-height: 25rem;
    min-height: 25rem;
    padding: 15px;
    border-radius: 25px;
    margin-left: 15px;

    img {
      max-width: 15rem;
      max-height: 15rem;
      border-radius: 15px;
    }
  }
}

.announcements-scroll-list {
  height: 65vh;
  overflow-y: scroll;
}

.announcement-horizontal-list::-webkit-scrollbar {
  // Width of vertical scroll bar
  width: 8px;
  // Height of horizontal scroll bar
  height: 10px;
  margin: 10px;
  border-radius: 8px;
  background-color: #c75d1e;
}
.announcement-horizontal-list::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #f4ac01;
}

@media (max-width: 1200px) {
  .sticky-text{
    float: left !important;
    text-align:left;

  }
  .sticky-note{
    margin-left: 5px !important;
    box-shadow: 0px 0x;

    img {
      max-width: 15rem !important;
      max-height: 15rem !important;
    }
  }
}

.announcement-slider-area {
  .sticky-note {
    margin: 10px;
    margin-left: 10rem; 
    max-width: 60rem;
    max-height: 25rem;
    min-height: 25rem;
    border-radius: 25px;
    box-shadow: 10px 10px 2px $theme-color;
    float: none;



    img {
      max-width: 25rem;
      max-height: 25rem;
      border-radius: 15px;
    }
  }

  .title {
    font-size: 1.8rem;
  }
  &:hover .nav-style-1 .ht-swiper-button-nav {
    visibility: visible;

    opacity: 1;
  }
  &.ml-10 {
    margin-left: 0;
  }
  @media #{$xl-layout} {
    &.ml-70 {
      margin-left: 15px;
    }
    &.mr-70 {
      margin-right: 15px;
    }
  }
  @media #{$lg-layout} {
    &.ml-70 {
      margin-left: 30px;
    }
    &.mr-70 {
      margin-right: 30px;
    }
  }
  @media #{$md-layout} {
    &.ml-70 {
      margin-left: 40px;
    }
    &.mr-70 {
      margin-right: 40px;
    }
  }
  @media #{$xs-layout} {
    &.ml-70 {
      margin-left: 15px;
    }
    &.mr-70 {
      margin-right: 15px;
    }
  }

  .swiper-pagination-bullets {
    position: absolute;
    right: 0;
    bottom: 26px;
    left: 0;

    text-align: center;
    .swiper-pagination-bullet {
      position: relative;

      display: inline-block;

      width: 14px;
      height: 14px;
      margin: 0 8px;

      opacity: 1;
      border-radius: 100%;
      background: #fff none repeat scroll 0 0;
      &:before {
        position: absolute;
        top: -3px;
        right: -3px;
        bottom: -3px;
        left: -3px;

        content: "";

        border: 1px solid transparent;
        border-radius: 100%;
      }
      &-active:before {
        border: 1px solid #fff;
      }
    }
  }
}

.announcement-slider-area {
  background-color: #f7f7f7;
  border-radius: 8px;
  border-top: 2px $theme-secondary-color solid;
  width: 100%;
  &:hover .nav-style-2.owl-carousel > .owl-nav div,
  &:hover .nav-style-2 .ht-swiper-button-nav {
    visibility: visible;

    opacity: 1;
  }
  &.ml-10 {
    margin-left: 0;
  }
}
